import { useState } from 'react';
import { Layout } from 'antd';
const { Content } = Layout;

function ResetPassword() {
    return (
      <Content>
        ResetPassword................
      </Content>
    );
  }
  
  export default ResetPassword;