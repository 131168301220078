export const LOGIN_PATH = "/login"
export const RESET_PWD = "/rest-password"
export const RISK_TREND_DATA = [
    ["Jan", 1.194, 1.420],
    ["Feb", 1.185, 1.422],
    ["Mar", 1.171, 1.425],
    ["Apr", 1.227, 1.432],
    ["May", 1.223, 1.438],
    ["Jun", 1.24, 1.488],
    ["Jul", 1.28, 1.536],
    ["Aug", 1.266, 1.519],
    ["Sep", 1.294, 1.553],
    ["Oct", 1.299, 1.559],
    ["Nov", 1.296, 1.555],
    ["Dec", 1.307, 1.568]
]
export const RISK_TREND_DATA2 = [
    ["Jan", 1.307, 1.568],
    ["Feb", 1.296, 1.555],
    ["Mar", 1.299, 1.559],
    ["Apr", 1.294, 1.553],
    ["May", 1.266, 1.519],
    ["Jun", 1.28, 1.536],
    ["Jul", 1.24, 1.488],
    ["Aug", 1.223, 1.438],
    ["Sep", 1.227, 1.432],
    ["Oct", 1.171, 1.425],
    ["Nov", 1.185, 1.422],
    ["Dec", 1.194, 1.420]
]

export const REVENUE_DATA = [
    ["Jan", 1194, 1320],
    ["Feb", 1185, 1372],
    ["Mar", 1171, 1325],
    ["Apr", 1227, 1432],
    ["May", 1123, 1338],
    ["Jun", 1140, 1388],
    ["Jul", 1180, 1400],
    ["Aug", 1266, 1519],
    ["Sep", 1294, 1553],
    ["Oct", 1199, 1559],
    ["Nov", 1196, 1555],
    ["Dec", 1307, 1568],
  ]

  export const REVENUE_DATA2 = [
    ["Jan", 1307, 1568],
    ["Feb", 1196, 1555],
    ["Mar", 1199, 1559],
    ["Apr", 1294, 1553],
    ["May", 1266, 1519],
    ["Jun", 1180, 1400],
    ["Jul", 1140, 1388],
    ["Aug", 1123, 1338],
    ["Sep", 1227, 1432],
    ["Oct", 1171, 1325],
    ["Nov", 1185, 1372],
    ["Dec", 1194, 1320],
  ]