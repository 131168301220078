import { Col, Row, Typography } from "antd";
import LineChart from "../../HOC/LineChart";
import "./RiskDashboard.css";
import { useEffect, useState } from "react";

const { Title } = Typography;



const riskData = {
  riskTrendData: [
    ["Month", "HCC", "Adjusted HCC"],
    ["Jan", 1.194, 1.420],
    ["Feb", 1.185, 1.422],
    ["Mar", 1.171, 1.425],
    ["Apr", 1.227, 1.432],
    ["May", 1.223, 1.438],
    ["Jun", 1.24, 1.488],
    ["Jul", 1.28, 1.536],
    ["Aug", 1.266, 1.519],
    ["Sep", 1.294, 1.553],
    ["Oct", 1.299, 1.559],
    ["Nov", 1.296, 1.555],
    ["Dec", 1.307, 1.568],
  ],
  options: {
    title: "HCC vs Adjusted HCC",
    legend: { position: "bottom" },
  },
}

const revenueOpportunity = {
  data: [
    ["Month", "Paid", "Adjusted"],
    ["Jan", 1194, 1320],
    ["Feb", 1185, 1372],
    ["Mar", 1171, 1325],
    ["Apr", 1227, 1432],
    ["May", 1123, 1338],
    ["Jun", 1140, 1388],
    ["Jul", 1180, 1400],
    ["Aug", 1266, 1519],
    ["Sep", 1294, 1553],
    ["Oct", 1199, 1559],
    ["Nov", 1196, 1555],
    ["Dec", 1307, 1568],
  ],
  options: {
    title: "PMPM Paid vs Adjusted Expected (Revenue)",
    legend: { position: "bottom" },
  },
}


function BottomDisk({chartSlice}) {
  const [state, setState] = useState({
    member_processed: {val: "1247" , sup: "25"},
    gaps_closed: {val: "762" , sup: "41"},
    queries_submitted: {val: "1512" , sup: "57"},
    revenueOpportunity: {sup: "1.07"}
  })

  useEffect(() => {
    if(chartSlice === "1"){
      setState({
        member_processed: {val: "1247" , sup: "25"},
        gaps_closed: {val: "762" , sup: "41"},
        queries_submitted: {val: "1512" , sup: "57"},
        revenueOpportunity: {sup: "1.07"}
      })
    }
    else{
      setState({
        member_processed: {val: "1512" , sup: "57"},
        gaps_closed: {val: "1247" , sup: "25"},
        queries_submitted: {val: "762" , sup: "41"},
        revenueOpportunity: {sup: "3.02"}
      })
    }
  },[chartSlice])
  return (
    <>
      <Row>
        <Col span={6}>
          <Title className="bottom-disk-title" level={4}>
            Members Processed
          </Title>
          <Title className="title-sup-class">{state?.member_processed?.val}<sup className="sup-color-class">{state?.member_processed?.sup}%</sup></Title>
        </Col>
        <Col span={6}>
          <Title className="bottom-disk-title" level={4}>
            Gaps Closed
          </Title>
          <Title className="title-sup-class">{state?.gaps_closed?.val}<sup className="sup-color-class">{state?.gaps_closed?.sup}%</sup></Title>
        </Col>
        <Col span={6}>
          <Title className="bottom-disk-title" level={4}>
            Queries Submitted
          </Title>
          <Title className="title-sup-class">{state?.queries_submitted?.val}<sup className="sup-color-class">{state?.queries_submitted?.sup}%</sup></Title>
        </Col>
        <Col span={6}>
          <Title className="bottom-disk-title" level={4}>
            Revenue Opportunity
          </Title>
          <Title className="title-sup-class"><sup className="sup-color-class">$</sup>{state?.revenueOpportunity?.sup}</Title>
        </Col>
      </Row>
      <Row>
        <Col span={12} id="riskTrend_div">
          <LineChart
            data={riskData?.riskTrendData}
            options={riskData?.options}
          />
        </Col>
        <Col span={12} id="revenueOpportunity_div">
          <LineChart
            data={revenueOpportunity?.data}
            options={revenueOpportunity?.options}
          />
        </Col>
      </Row>
    </>
  );
}
export default BottomDisk;
