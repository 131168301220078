import PieChart from "../../HOC/PieChart";
import BottomDisk from "./BottomDisk";
import { Chart } from "react-google-charts";
import { Col, Row, Typography } from "antd";
import {RISK_TREND_DATA, RISK_TREND_DATA2, REVENUE_DATA2, REVENUE_DATA} from "../../constants"
import { useState } from "react";

const pieDataNew = [
  ["Plan", "Population"],
  ["Medicare ACO", 10000],
  ["Medicare Advantage", 7000],
];

const pieDataOld = [
  ["Plan", "Population"],
  ["Medicare ACO", 2000],
  ["Medicare Advantage", 1400],
];

export const pieDiffdata = {
  old: pieDataOld,
  new: pieDataNew,
};

const options = {
  title: "Monthly RAF Population by Plan",
  pieSliceText: "label",
  legend: { position: "side" },
  isStacked: true,
};

const RiskDashboard = () => {
  const [chartSlice, setChartSlice] = useState("1")
  const stackData = [
    ["Month", "Assigned", "Completed"],
    ["Beth", 980, 738],
    ["Mary", 1000, 820],
    ["Susan", 820, 710],
    ["Nicole", 977, 810],
    ["Jennifer", 1015, 891],
  ];

  const stackData1 = [
    ["Beth", 80, 38],
    ["Mary", 100, 80],
    ["Susan", 80, 71],
    ["Nicole", 97, 81],
    ["Jennifer", 115, 91],
  ];

  const stackData2 = [
    ["Beth", 20, 38],
    ["Mary", 20, 30],
    ["Susan", 8, 17],
    ["Nicole", 27, 41],
    ["Jennifer", 15, 32],
  ];

  const barChartData = [
    ["Chronic", "Patient Count"],
    ["Osteoporosis", 337],
    ["Renal Disease", 1485],
    ["Anxiety Disorder", 4840],
    ["CHF", 6574],
    ["Joint Disorders", 6946],
    ["Depression", 8525],
    ["COPD", 9478],
    ["Asthma", 6993],
    ["Hypertension", 7775],
    ["CAD", 8966],
    ["Diabetes", 9432],
  ];

  const barChartData1 = [
    ["Osteoporosis", 37],
    ["Renal Disease", 85],
    ["Anxiety Disorder", 48],
    ["CHF", 65],
    ["Joint Disorders", 69],
    ["Depression", 85],
    ["COPD", 78],
    ["Asthma", 69],
    ["Hypertension", 75],
    ["CAD", 89],
    ["Diabetes", 94],
  ];

  const barChartData2 = [
    ["Osteoporosis", 33],
    ["Renal Disease", 14],
    ["Anxiety Disorder", 40],
    ["CHF", 74],
    ["Joint Disorders", 46],
    ["Depression", 25],
    ["COPD", 47],
    ["Asthma", 63],
    ["Hypertension", 57],
    ["CAD", 36],
    ["Diabetes", 43],
  ];

  const stackOptions = {
    title: "Monthly Assigned vs Audited",
    vAxis: { title: "Member Count" },
    hAxis: { title: "Reviewer / Coder" },
    legend: { position: "top", maxLines: 3 },
    seriesType: "bars",
    series: { 5: { type: "line" } },
    axes: {
      x: {
        0: { side: "top" },
      },
    },
  };

  const barchartOptions = {
    title: "Chronic Conditions",
    vAxis: { title: "Chronic" },
    hAxis: { title: "Patient Count" },
    legend: { position: "bottom" },
  };

  const addColumnsToComboChart = (comboChart_data) => {
    comboChart_data.addColumn({ type: "string", id: "Month" });
    comboChart_data.addColumn({ type: "number", id: "Assigned" });
    comboChart_data.addColumn({ type: "number", id: "Completed" });
  };

  const addColumnsToBarChart = (comboChart_data) => {
    comboChart_data.addColumn({ type: "string", id: "Chronic" });
    comboChart_data.addColumn({ type: "number", id: "Patient Count" });
  };

  const addColumnsToLineChart = (riskTrendChart_data) => {
    riskTrendChart_data.addColumn({ type: "string", id: "Month" });
    riskTrendChart_data.addColumn({ type: "number", id: "HCC" });
    riskTrendChart_data.addColumn({ type: "number", id: "Adjusted HCC" });
  };

  const handleChangeValues = (val, google) => {
    setChartSlice(val)
    let comboChart_data = new google.visualization.DataTable();
    addColumnsToComboChart(comboChart_data);
    comboChart_data.addRows(val === "1" ? stackData1 : stackData2);
    let combo_chart = new google.visualization.ComboChart(
      document.getElementById("comboChart_div")
    );
    combo_chart.draw(comboChart_data, stackOptions);
    let barChart_data = new google.visualization.DataTable();
    addColumnsToBarChart(barChart_data);
    barChart_data.addRows(val === "1" ? barChartData1 : barChartData2);
    let bar_chart = new google.visualization.BarChart(
      document.getElementById("barChart_div")
    );
    bar_chart.draw(barChart_data, barchartOptions);
    let riskTrendChart_data = new google.visualization.DataTable();
    addColumnsToLineChart(riskTrendChart_data);
    riskTrendChart_data.addRows(val === "1" ? RISK_TREND_DATA : RISK_TREND_DATA2);
    let LineChart1_chart = new google.visualization.LineChart(
      document.getElementById("riskTrend_div")
    );
    LineChart1_chart.draw(riskTrendChart_data, {
      title: "HCC vs Adjusted HCC",
      legend: { position: "bottom" },
    },);
    let revenueOpportunityChart_data = new google.visualization.DataTable();
    addColumnsToLineChart(revenueOpportunityChart_data);
    revenueOpportunityChart_data.addRows(val === "1" ? REVENUE_DATA : REVENUE_DATA2);
    let LineChart2_chart = new google.visualization.LineChart(
      document.getElementById("revenueOpportunity_div")
    );
    LineChart2_chart.draw(revenueOpportunityChart_data, {
      title: "PMPM Paid vs Adjusted Expected (Revenue)",
      legend: { position: "bottom" },
    });
  };

  return (
    <>
      <Row>
        <Typography.Title level={2}>Risk Adjustment Dashboard</Typography.Title>
      </Row>
      <Row>
        <Col span={8}>
          <PieChart
            diffdata={pieDiffdata}
            options={options}
            handleChangeValues={handleChangeValues}
          />
        </Col>
        <Col span={10} id="comboChart_div">
          <Chart
            chartType="ComboChart"
            width="100%"
            height="100%"
            data={stackData}
            options={stackOptions}
          />
        </Col>
        <Col span={6} id="barChart_div">
          <Chart
            chartType="BarChart"
            width="100%"
            height="100%"
            data={barChartData}
            options={barchartOptions}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BottomDisk chartSlice={chartSlice}/>
        </Col>
      </Row>
    </>
  );
};
export default RiskDashboard;
