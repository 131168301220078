import React from "react";
import { Chart } from "react-google-charts";

function PieChart({ diffdata, data, options, handleChangeValues }) {
  return (
    <Chart
      chartType="PieChart"
      diffdata={diffdata}
      data={data}
      options={options}
      width={"100%"}
      height={"300px"}
      chartEvents={[
        {
          eventName: "ready",
          callback: ({ chartWrapper, google }) => {
            const chart = chartWrapper.getChart();
            google.visualization.events.addListener(chart, "click", e => {
              handleChangeValues(e.targetID?.split("slice#")?.[1], google)
            });
          }
        }
      ]}
    />
  );
}

export default PieChart;
